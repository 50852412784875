/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'wrench-adjustable': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 4.5a4.5 4.5 0 01-1.703 3.526L13 5l2.959-1.11q.04.3.041.61"/><path pid="1" d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 007.29 6.092l-6.116 5.096a2.583 2.583 0 103.638 3.638L9.908 8.71A4.5 4.5 0 0011.5 9m-1.292-4.361l-.596.893.809-.27a.25.25 0 01.287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 01-.287-.376l.596-.893-.809.27a.25.25 0 01-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 01.287.376M3 14a1 1 0 110-2 1 1 0 010 2"/>',
    },
});
